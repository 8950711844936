import config from '@/utils/config'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    user: null,
    access_token: null,
    id_token: null,
    state: null,
  },
  getters: {
    // small
  },
  mutations: {
    // caps
    UPDATE_AUTHENTICATION(state, data) {
      state.access_token = data.access_token
      state.id_token = data.id_token
      state.state = data.state
    },
    UPDATE_AUTH_USER(state, data) {
      state.user = data
    },
  },
  actions: {
    login(state, payload) {
      return axios
        .post(
          `${config.authServerEndpoint}api/v1/auth/login?redirect_uri=${
            config.redirectUrl
          }&client_id=${
            config.clientId
          }&response_type=token%20id_token&scope=openid profile contact&nonce=${new Date().getTime()}`,
          payload,
        )
        .then(res => res)
        .catch(err => err)
    },
    getUserInfo(state, idToken) {
      return axios
        .get(`${config.authServerEndpoint}api/v1/auth/userinfo`, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        })
        .then(res => res)
        .catch(err => err)
    },
  },
}
